import { Container } from '../../core/layout/Layout';
import Button from '../../core/buttons/Button';
import React, { ReactNode } from 'react';
import { FluidObject } from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import tw from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { UgoPharmaPdfDownloadButtonQuery } from '../../../../generated/graphql-types';
import ContactFormButton from '../ContactFormButton/ContactFormButton';

const firstPartstyles = {
  half: tw`w-full py-16 md:flex-grow md:w-1/2`,
  twoThirds: tw`w-full py-16 md:flex-grow md:w-2/3`,
};

const secondPartStyles = {
  half: tw`w-full md:w-1/2 h-0 md:h-auto md:flex-grow`,
  oneThird: tw`w-full md:w-1/3 h-0 md:h-auto md:flex-grow`,
};

const firstPartContainerStyles = {
  half: tw`max-w-xl! space-y-8`,
  twoThirds: tw`max-w-3xl! space-y-8`,
};

const wrapperStyles = {
  withBottomMargin: tw`grid grid-cols-1 md:flex md:flex-wrap mb-16`,
  withoutBottomMargin: tw`grid grid-cols-1 md:flex md:flex-wrap`,
};

const TwoPartLayout = ({
  children,
  imageFluid,
  halfLayout = false,
  removeBottomMargin = false,
  downloadPharmaPdfButton = false,
}: {
  children: ReactNode | ReactNode[];
  imageFluid: FluidObject | FluidObject[];
  halfLayout?: boolean;
  removeBottomMargin?: boolean;
  downloadPharmaPdfButton?: boolean;
}) => {
  const firstPartSelectedStyle = halfLayout
    ? firstPartstyles.half
    : firstPartstyles.twoThirds;
  const secondPartSelectedStyle = halfLayout
    ? secondPartStyles.half
    : secondPartStyles.oneThird;
  const firstPartContainerSelectedStyle = halfLayout
    ? firstPartContainerStyles.half
    : firstPartContainerStyles.twoThirds;
  const wrapperSelectedStyle = removeBottomMargin
    ? wrapperStyles.withoutBottomMargin
    : wrapperStyles.withBottomMargin;
  return (
    <div css={wrapperSelectedStyle}>
      <div css={firstPartSelectedStyle} style={{ backgroundColor: '#FEF7EB' }}>
        <Container css={firstPartContainerSelectedStyle}>
          {children}
          <div tw="flex justify-center md:justify-start">
            {downloadPharmaPdfButton ? (
              <UgoPharmaPdfDownloadButton />
            ) : (
              <ContactFormButton />
            )}
          </div>
        </Container>
      </div>
      <div css={secondPartSelectedStyle}>
        <BackgroundImage fluid={imageFluid} tw="h-full" />
      </div>
    </div>
  );
};

export default TwoPartLayout;

const UgoPharmaPdfDownloadButton = () => {
  const pharmaPdfDownloadButtonData = useStaticQuery<
    UgoPharmaPdfDownloadButtonQuery
  >(graphql`
    query UgoPharmaPdfDownloadButton {
      pharmaFile: file(relativePath: { eq: "UGO-pharma.pdf" }) {
        publicURL
      }
    }
  `);
  return (
    <>
      <a href={pharmaPdfDownloadButtonData.pharmaFile.publicURL} download>
        <Button variant="filled" color="primary">
          Scarica la presentazione
        </Button>
      </a>
    </>
  );
};
