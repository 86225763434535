import React from 'react';
import SEO from '../components/core/meta/SEO';
import { Container } from '../components/core/layout/Layout';
import Heading from '../components/core/typography/Heading';
import { graphql, Link, useStaticQuery } from 'gatsby';
import ResponsiveList from '../components/views/ResponsiveList/ResponsiveList';
import tw from 'twin.macro';
import PartnersList from '../components/partials/PartnersList/PartnersList';
import BackgroundImage from 'gatsby-background-image';
import { AziendePageQuery } from '../../generated/graphql-types';
import TwoPartLayout from '../components/partials/TwoPartLayout/TwoPartLayout';
import BrochureBanner from '../components/partials/BrochureBanner/BrochureBanner';
import ContactFormButton from '../components/partials/ContactFormButton/ContactFormButton';

const detailsData = [
  { title: 'Servizi', amount: '30K' },
  { title: 'Utenti', amount: '10K' },
  { title: 'Feedback', amount: '4.9' },
  { title: 'Operatori', amount: '500' },
];

const content = [
  {
    title: 'Aziende farmaceutiche',
    description:
      'Oggi e da tempo si parla molto di paziente al centro. Curare la persona, non la malattia. Ma come farlo? Quali sono i bisogni del paziente e della famiglia? Quali gli ostacoli per accedere alla salute e aderire alla terapia? Che cosa offrire oltre al prodotto?',
    link: '/aziende-farmaceutiche',
  },
  {
    title: 'Welfare aziende',
    description:
      'Il lavoro è dinamico, le persone sono dinamiche. La risorsa più scarsa oggi è il tempo. La famiglia in Italia è il pilastro dell’assistenza ma oggi è disgregata, la donna lavora fuori casa, i figli sono meno e più lontani. Come aiutare i dipendenti a gestire i carichi di cura? Quali sono oggi i bisogni del lavoratore? Come favorire energia, concentrazione e motivazione?',
    link: '/welfare-aziendale',
  },
  {
    title: 'Welfare territoriale',
    description:
      'La sanità è impegnata in una sfida gigantesca per rispondere ai bisogni di una popolazione che invecchia. Cosa resta del sistema socio-assistenziale? E di quello territoriale? In che modo i servizi privati e digitali possono supportare entrambi? In UGO abbiamo più di un’idea (ed esperienza!).',
    link: '/welfare-territorio',
  },
  {
    title: 'Compagnie assicurative',
    description:
      'Oltre frontiera è chiaro, in Italia ci stiamo avvicinando: il sistema assicurativo è un player fondamentale nello scenario assistenziale. Assicurazioni sanitarie private e aziendali, long term care, family care. Quali tutele cercano le famiglie? Quali servizi sono e saranno i più utili e i più richiesti? Noi scommettiamo su almeno due!',
    link: '/assicurazioni',
  },
  {
    title: 'Associazioni e Fondazioni',
    description:
      'Il terzo settore è un pilastro del nostro paese e deve restare tale. Ma da solo non può farcela. I motivi sono principalmente due: la domanda è troppa ed esploderà; deve digitalizzarsi per avere dei processi sostenibili. A entrambi i problemi esiste una soluzione: la sinergia con realtà profit che si occupano di questo. UGO collabora con associazioni e fondazioni a supporto dei cittadini fragili e il modello - certo che sì - è replicabile.',
    link: '/terzo-settore',
  },
];

const assistanceContent = [
  'Abbiamo studiato il mercato, osservato quello che c’è e quello che manca e abbiamo lanciato UGO. Vediamo in UGO il servizio che rivedrà il nostro concetto di assistenza e lo innoverà. Crediamo che il Caregiver professionale, accanto a quello familiare, sia la chiave di una buona gestione della fragilità e dei carichi di cura che ognuno di noi, presto o tardi, deve gestire.',
  'Il servizio consiste nell’affiancare alla persona anziana o con una o più patologie o con disabilità, un operatore esterno alla famiglia che la aiuti a gestire le attività quotidiane e di salute. Senza pressione psicologica, senza rinunce, senza stress aggiuntivo.',
  "UGO ti raggiunge a casa, ti accompagna all'appuntamento, ti affianca in ogni momento e ti riporta a casa. Può svolgere anche le commissioni e farti compagnia.",
  'UGO è una persona e lavora col supporto di una piattaforma digitale. Questo significa che è sempre disponibile, si organizza in pochissimo tempo, è tracciato e controllato, sia il servizio che il pagamento. Non è poco!',
  'UGO rivoluziona il nostro concetto di assistenza leggera e garantisce a ogni persona un aiuto su misura ed efficace. Non è questo che vogliamo per noi e per chi amiamo?',
];
const AziendePage = () => {
  const aziendeData = useStaticQuery<AziendePageQuery>(graphql`
    query AziendePage {
      headerBackground: file(
        relativePath: { eq: "b2b/headers/UGO-assistance.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
      assistanceImage: file(relativePath: { eq: "b2b/caregiver.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
      companyBanner: file(relativePath: { eq: "b2b/company-banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
    }
  `);
  return (
    <>
      <SEO title={'Aziende'} url={'/aziende'} />
      <BackgroundImage
        fluid={aziendeData.headerBackground.childImageSharp.fluid}
      >
        <div
          tw="bg-black bg-opacity-60 py-16 flex"
          style={{ minHeight: '460px' }}
        >
          <Container tw="flex flex-col justify-center">
            <Heading variant="h2" tw="text-center text-white">
              {"L'assistenza on-demand per supportare le persone e le aziende"}
            </Heading>
            <div tw="flex justify-center">
              <ContactFormButton />
            </div>
          </Container>
        </div>
      </BackgroundImage>
      <div tw="pt-16">
        <Container tw="mb-16 text-center">
          <p
            style={{
              fontSize: '17px',
              lineHeight: '32px',
              marginBottom: '1rem',
            }}
          >
            Siamo il primo servizio digitale on-demand di supporto alla
            fragilità e siamo una PMI innovativa. Lavoriamo per garantire
            dignità e indipendenza a ogni persona, rimuovendo gli ostacoli
            logistici, psicologici e culturali che rendono la vita quotidiana
            complessa. Non solo. Diamo alle famiglie uno strumento per vivere e
            lavorare bene.
          </p>
        </Container>
        <Container tw="mb-16">
          <div tw="-mx-5">
            <ResponsiveList
              sliderProps={{
                navOutside: true,
                darkControls: true,
              }}
              twGridItemSetting={tw`md:w-1/4 px-10 py-4`}
            >
              {detailsData.map((d, idx) => (
                <div key={idx}>
                  <div tw="flex justify-center">
                    <span tw="text-7xl font-bold">{d.amount}</span>
                  </div>
                  <div tw="flex justify-center">
                    <span
                      tw="text-lg font-semibold"
                      style={{ color: '#F8B336' }}
                    >
                      {d.title}
                    </span>
                  </div>
                </div>
              ))}
            </ResponsiveList>
          </div>
        </Container>
        <TwoPartLayout
          imageFluid={aziendeData.assistanceImage.childImageSharp.fluid}
        >
          <h4 tw="text-h4-mobile md:text-h4 tracking-h4 font-bold mb-8">
            {
              'Nel 2018 siamo entrati nel mondo dei servizi di assistenza. Per portare il '
            }
            <span style={{ color: '#9D4DE1' }}>{'Caregiver.'}</span>
          </h4>
          {assistanceContent.map((t, idx) => (
            <p
              key={idx}
              style={{
                fontSize: '17px',
                lineHeight: '32px',
                marginBottom: '1rem',
              }}
            >
              {t}
            </p>
          ))}
        </TwoPartLayout>
        <Container tw="mb-16">
          <PartnersList />
        </Container>
        <BackgroundImage
          fluid={aziendeData.companyBanner.childImageSharp.fluid}
          tw="mb-16 relative"
        >
          <div
            tw="absolute inset-0"
            style={{
              content: '""',
              backgroundColor: 'rgba(157, 77, 225)',
              mixBlendMode: 'multiply',
            }}
          ></div>
          <div tw="py-16 flex relative" style={{ minHeight: '300px' }}>
            <Container tw="flex flex-col gap-2 justify-center items-center text-center">
              <p
                tw="text-base md:text-xl"
                style={{
                  lineHeight: '32px',
                  marginBottom: '1rem',
                  color: '#FFFFFF',
                  fontWeight: 700,
                }}
              >
                {
                  'Dal 2018 siamo impegnati in quello che oltre a un progetto è una forte missione. E non siamo soli. Collaboriamo con aziende di diversi settori che condividono con noi vision e valori e hanno voglia di portare cambiamento. Il servizio UGO è sempre lo stesso ma i progetti cambiano per accogliere le esigenze e gli obiettivi di ogni partner.'
                }
              </p>
              <div tw="flex justify-center">
                <ContactFormButton />
              </div>
            </Container>
          </div>
        </BackgroundImage>
        <Container tw="mb-16">
          {content.map((c, idx) => (
            <div key={idx} tw="mb-16">
              <h2 tw="text-h2-mobile md:text-h2 tracking-h2 font-bold mb-8">
                {c.title}
              </h2>
              <p
                style={{
                  fontSize: '17px',
                  lineHeight: '32px',
                  marginBottom: '1rem',
                }}
              >
                {c.description}
              </p>
              <Link to={c.link} tw="text-primary-500 underline font-bold">
                {'Leggi di più >'}
              </Link>
            </div>
          ))}
        </Container>
        <BrochureBanner />
      </div>
    </>
  );
};

export default AziendePage;
