import Button from '../../core/buttons/Button';
import React from 'react';

const ContactFormButton = () => {
  return (
    <a href={'https://hellougo.typeform.com/partner-site'} target={'_blank'}>
      <Button variant="filled" color="primary">
        Parliamone
      </Button>
    </a>
  );
};

export default ContactFormButton;
